import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';

const NavBar = ({ className }) => {
    return <Header className={className} logo="Send Silence Packing" />;
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
