// Import common global styles
import '../styles/app.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { App, createStore } from 'cccisd-laravel-boilerplate';
import reducers from './reducers.js';
import Routes from './routes.js';

const store = createStore(reducers);

ReactDOM.render(
    <App store={store}>
        <Routes />
    </App>,
    document.getElementById('app')
);
